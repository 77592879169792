/* src/index.css */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans:wght@400;700&family=Red+Hat+Display:wght@400;700&display=swap');

@tailwind base;
@tailwind components;

/* Import custom components before utilities */
@import './Styles/styles.css';

@tailwind utilities;

html {
	@apply scroll-smooth;
}
